<template>
	<div class="admin-container">
		<Head />
		<div class="accr_box whole_000 whole_fff">
			<div class="container">
				<div class="accr-box">
					<div class="accr-top flex flex-ac">
<!--						<i class="el-icon-arrow-left"></i>-->
						<p>{{$t('wittit')}}</p>
						<!-- <el-button class="recharge-code" type="primary" @click="toCode">{{$t('witbtn')}}</el-button> -->
					</div>
					<div class="accr-sel">
						<el-form ref="form" :rules="rules" :model="form">
							<el-form-item prop="coin">
								<div class="accr_sel">
									<div class="accr_sel_t">{{$t('czTxt2')}}</div>
									<el-select v-model="form.coin" :placeholder="$t('placeholder3')"
										@change="coinChange">
										<el-option v-for="(item,index) in options" :key="index" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
							<el-form-item prop="protocolName">
								<div class="accr_sel">
									<div class="accr_sel_t">{{$t('czTxt3')}}</div>
									<el-select v-model="form.protocolName" :placeholder="$t('placeholder3')"
										@change="protocolChange">
										<el-option v-for="(item,index) in options2" :key="index"
											:label="item.protocolName" :value="item.protocol">
										</el-option>
									</el-select>
									<div class="accr_sel_ft">{{$t('czTxt4')}}：{{balance}}</div>
								</div>
							</el-form-item>
							<el-form-item prop="address">
								<div class="accr_sel">
									<div class="accr_sel_t">{{$t('czTxt5')}}</div>
									<el-input v-model="form.address" :placeholder="$t('placeholder2')"></el-input>
								</div>
							</el-form-item>
							<el-form-item prop="amount">
								<div class="accr_sel accr_sel_input">
									<div class="accr_sel_t">{{$t('czTxt6')}}</div>
									<el-input v-model="form.amount" :placeholder="$t('placeholder2')">
										<template slot="suffix">{{unit}}</template>
									</el-input>
									<div class="accr_sel_ftt flex flex-ac flex-zBetween">
										<p>{{$t('czTxt7')}}：<span>{{comMoney}} {{unit}}</span></p>
										<p>{{$t('czTxt8')}}：<span>{{comFee}} {{unit}}</span></p>
									</div>
								</div>
							</el-form-item>
							<el-form-item>
								<div class="accr-foot">
									<el-button type="primary" size="large" class="btn" @click="applyShow">{{$t('btn5')}}</el-button>
								</div>
							</el-form-item>
							<div class="accr_cozy">
								<div class="accr_co_t">{{$t('czTxt9')}}</div>
								<div class="accr_co_p">
									<p class="ellipsis">• {{$t('czTxt17')}} {{min}} {{unit}}。</p>
									<p class="ellipsis">• {{$t('czTxt16')}}</p>
									<p class="ellipsis">• {{$t('czTxt14')}}</p>
								</div>
							</div>
						</el-form>
					</div>
					<div class="api_flex_table">
						<div class="api_table_tt">{{$t('wittext')}}</div>
						<div class="exte-table accr_table exte-box" style="margin: 0;">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="addTime" :label="$t('czth1')">
									<template slot-scope="scope">
										<span>{{ scope.row.addTime | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="coinName" :label="$t('czTxt2')">
								</el-table-column>
								<el-table-column prop="protocolName" :label="$t('recth2')" width="200">
								</el-table-column>
								<el-table-column prop="address" :label="$t('czTxt5')">
								</el-table-column>
								<el-table-column prop="money" :label="$t('czTxt6')">
								</el-table-column>
								<el-table-column prop="fee" :label="$t('czTxt8')">
								</el-table-column>
								<el-table-column prop="hash" label="Txid">
								</el-table-column>
								<el-table-column prop="status" :label="$t('wtth8')">
									<template slot-scope="scope">
										<span v-if="scope.row.status == -1">{{$t('witstatus_3')}}</span>
										<span v-else-if="scope.row.status == 0">{{$t('witstatus_1')}}</span>
										<span v-else-if="scope.row.status == 1">{{$t('witstatus_2')}}</span>
										<span v-else-if="scope.row.status == 2">{{$t('witstatus_4')}}</span>
										<span v-else-if="scope.row.status == 3">{{$t('witstatus_3')}}</span>
										<span v-else>--</span>
									</template>
								</el-table-column>
								<template slot="empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
		<el-dialog :visible.sync="dialogVisible" width="380px" custom-class="custom-dialog custom-dialog-tip">
			<div slot="title" class="dialog-title"><b>{{$t('edit')}}</b></div>
			<div class="dialog-content">
				<div class="regi_group api-item">
					<div class="regi_gr_t">{{$t('acTxt6')}}</div>
					<div class="regi_gr_b flex flex-ac">
						<el-input v-model="jyPassword" show-password type="password" placeholder=""></el-input>
					</div>
				</div>
				<div class="regi_group api-item" v-if="codeType==3">
					<div class="regi_gr_t">{{$t('chTxt12')}}</div>
					<div class="regi_gr_b flex flex-ac">
						<el-input v-model="code" placeholder=""></el-input>
					</div>
				</div>
				<!-- 邮箱验证码 -->
				<div class="regi_group api-item" v-if="codeType==1">
					<div class="regi_gr_t">{{$t('chTxt12')}}</div>
					<div class="regi_gr_b flex flex-ac">
						<el-input v-model="code" placeholder=""></el-input>
						<el-button :disabled="sendMsgDisabled" class="btn btn_yzm" @click="sendMobile(3)" type="primary">
							<span v-if="sendMsgDisabled">{{time+$t('apisecond')}}</span>
							<span v-if="!sendMsgDisabled">{{$t('clickget')}}</span>
						</el-button>
					</div>
				</div>
				<!-- 手机验证码 -->
				<div class="regi_group api-item" v-if="codeType==2">
					<div class="regi_gr_t">{{$t('chTxt12')}}</div>
					<div class="regi_gr_b flex flex-ac">
						<el-input v-model="code" placeholder=""></el-input>
						<el-button :disabled="sendMsgDisabled2" class="btn btn_yzm" @click="sendMobile(2)"
							>
							<span v-if="sendMsgDisabled2">{{time2+$t('apisecond')}}</span>
							<span v-if="!sendMsgDisabled2">{{$t('clickget')}}</span>
						</el-button>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-row :gutter="14">
					<el-col :span="12">
						<el-button @click="dialogVisible = false">{{$t('cancel')}}
						</el-button>
					</el-col>
					<el-col :span="12">
						<el-button type="primary" @click="apply">{{$t('confirm2')}}
						</el-button>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		coinList,
		coinBalance,
		withdrawList,
		securitySet,
		emailWithdrawCode,
		mobileWithdrawCode,
		withdrawCreate
	} from '@/api/api/user'
	import {
		accSub
	} from "../../assets/js/decimal";
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				options: [],
				coinextList: [],
				balance: 0,
				unit: '',
				value: '',
				label: '',
				options2: [],
				value1: '',
				input: '',
				input1: '',
				form: {
					coin: '',
					protocolName: '',
					address: '',
					amount: '',
				},
				rules: {

				},
				min: '--',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				coinextItem: {},
				dialogVisible: false,
				jyPassword: '',
				code: '',
				time: 60,
				time2: 60,
				sendMsgDisabled: false,
				sendMsgDisabled2: false,
				codeType: 1,
			};
		},
		created() {
			this.unit = this.$route.query.name
		},
		mounted() {
			this.getList()
			this.getCoin()
			this.getMember()
		},
		methods: {
			getMember() {
				//获取个人安全信息
				securitySet().then(resp => {
					if (resp.code == 0) {
						this.user = resp.data;
						if (resp.data.realName == null || resp.data.realName == "") {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: this.$t("submittip1")
							});
							// 判断是否实名认证，未认证跳转到实名认证页面；
							//this.$Message.success(this.$t("otc.publishad.submittip1"));
							this.$router.push('/admin')
						}
						// else if (resp.data.phoneVerified == 0) {
						//   this.$Notice.error({
						//     title: this.$t("common.tip"),
						//     desc: this.$t("otc.publishad.submittip2")
						//   });
						//   // 判断是否是手机号0，1，未认证跳转到实名认证页面；
						//   //this.$Message.success(this.$t("otc.publishad.submittip2"));
						//   self.$router.push("/uc/safe");
						// }
						if (resp.data.fundsVerified == 0) {
							this.$notify.error({
								title: this.$t("apiTxt2"),
								message: this.$t("submittip3")
							});
							// 判断是否设置交易密码，未认证跳转到实名认证页面；
							//this.$Message.success(this.$t("otc.publishad.submittip3"));
							// self.$router.push("/uc/safe");
						}


						if (resp.data.emailVerified == 1) {
							this.codeType = 1;
						}
						if (resp.data.phoneVerified == 1) {
							this.codeType = 2;
						}
						if (resp.data.googleStatus == 1) {
							this.codeType = 3;
						}


					} else {
						this.$message.error(resp.message);
					}
				});

			},
			applyShow() {
				if (!this.form.coin || !this.form.protocolName) {
					this.$message.error(this.$t('witerr'));
					return false
				}
				if (!this.form.address) {
					this.$message.error(this.$t('addresstip'));
					return false
				}
				if (this.form.money <= 0) {
					this.$message.error(this.$t('witplaceholder'));
					return false
				}
				// if (this.form.money > this.coinextItem.maxwithdraw) {
				// 	this.$message.error(this.$t('uc.finance.withdraw.moneymaxttip') + this.coinextItem.maxwithdraw);
				// 	return false
				// }
				// if (this.form.money < this.coinextItem.minwithdraw) {
				// 	this.$message.error(this.$t('uc.finance.withdraw.moneyminttip') + this.coinextItem.minwithdraw);
				// 	return false
				// }
				// if (this.comMoney <= 0) {
				// 	this.$message.error(this.$t('uc.finance.withdraw.moneyttip'));
				// 	return false
				// }

				this.dialogVisible = true

			},
			sendMobile(index) {
				let me = this;
				if (index == 2) {
					//获取手机code
					mobileWithdrawCode({
						coin: this.form.coin
					}).then(res => {
						if (res.code == 0) {
              me.sendMsgDisabled2 = true;
							let interval = window.setInterval(function() {
								if (me.time-- <= 0) {
									me.time = 60;
									me.sendMsgDisabled2 = false;
									window.clearInterval(interval);
								}
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					})
				}
				if (index == 3) {
					//获取邮件验证
					emailWithdrawCode({
						coin: this.form.coin
					}).then(res => {
						if (res.code == 0) {
							this.sendMsgDisabled = true;
							let interval = window.setInterval(function() {
								if (me.time2-- <= 0) {
									me.time2 = 60;
									me.sendMsgDisabled = false;
									window.clearInterval(interval);
								}
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					})
				}

			},
			apply() {
				if (!this.form.coin || !this.form.protocolName) {
					this.$message.error(this.$t('witerr'));
					return false
				}
				if (!this.form.address) {
					this.$message.error(this.$t('addresstip'));
					return false
				}
				if (this.form.amount <= 0) {
					this.$message.error(this.$t('witplaceholder'));
					return false
				}
				if (!this.code) {
					this.$message.error(this.$t("apierr4"));
					return false
				}
				//获取
				let data = {
					coinName: this.form.coin,
					coinprotocol: this.form.protocolName,
					address: this.form.address,
					money: this.form.amount,
					payPwd: this.jyPassword,
					code: this.code,
					codeType: this.codeType
				}

				withdrawCreate(data).then(resp => {
					if (resp.code == 0) {
						this.$message.success(this.$t('trSuccess'));
						this.form.amount = this.oldMoney
						this.form.address = ""
						this.jyPassword = ""
						this.dialogVisible = false
						this.getBalance()
						this.getList()
					} else {
						this.$message.error(resp.message);
					}
				})
			},
			getList() {
				withdrawList({
					page: this.currentPage - 1,
					pageSize: this.pageSize
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.tableData = res.data.content
						this.pageSize = res.data.size
						this.total = res.data.totalElements
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			getCoin() {
				// coinList().then(res => {
				// 	if (res.code == 0) {
				// 		this.options = res.data.coinList
				// 		this.coinextList = res.data.coinextList
				// 	}
				// })

        coinList().then(res => {
          if (res.code == 0) {
            this.coinextList = res.data.coinextList
            this.options=[];
            res.data.coinList.forEach(item => {
              let isHas = false;
              this.coinextList.forEach(coinext => {
                if (coinext.coinName == item.name && coinext.isWithdraw == "IS_TRUE") {
                  isHas = true;
                }
              })
              if (isHas) {
                this.options.push(item)
              }
            })
          }
        })
			},
			coinChange(value) {
				// this.options2 = this.coinextList.filter(item => {
				// 	return item.coinName == value
				// })
				// this.coinextList.forEach(item => {
				// 	if (item.coinName == value) {
				// 		this.options2.push(item)
				// 	}
				// })
				this.options2 = []
				this.coinextList.forEach(item => {
					if (item.coinName == value) {
						this.options2.push(item)
					}
				})
				this.form.protocolName = ''
				this.unit = value
				this.getBalance()
			},
			getBalance() {
				coinBalance({
					coinName: this.unit
				}).then(res => {
					if (res.code == 0) {
						this.balance = res.data
					}
				})
			},
			protocolChange(value) {
				let obj = this.options2.filter(item => {
					return item.protocol == value
				})
				this.coinextItem = obj[0]
				this.form.amount = obj[0].minwithdraw
				this.min = obj[0].minwithdraw
			},
			toCode() {
				this.$router.push('/WithdrawCode')
			},
			_accMul(arg1, arg2) { //乘法
				if (arg1 == 0 || !arg1) return "0";
				if (arg2 == 0 || !arg2) return "0";
				var m = 0,
					s1 = arg1.toString(),
					s2 = arg2.toString();
				try {
					m += s1.split(".")[1].length
				} catch (e) {
					// console.log(e);
				}
				try {
					m += s2.split(".")[1].length
				} catch (e) {
					// console.log(e);
				}
				return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
			}
		},
		computed: {
			comFee() {
				let item = this.coinextItem
				let withdrawFee = item.withdrawFee || 0
				let minWithdrawFee = item.minWithdrawFee || 0
				let fee = this._accMul(withdrawFee, this.form.amount)
				if (fee < minWithdrawFee) {
					fee = minWithdrawFee
				}
				return fee
			},
			comMoney() {
				let fee = this.comFee
        if(this.form.amount==undefined || this.form.amount==null){
          return 0;
        }
				let money = accSub(this.form.amount, fee)
        if(money<0){
          money = 0;
        }
				return money
			}
		}
	}
</script>
